import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Block } from "components/atoms"
import { Header, ContactUs } from "components/molecules"
import { Section, Footer } from "components/organisms"
import { withTheme } from "hocs"
import { scroller } from "react-scroll"
import get from "lodash/get"
import config from 'react-reveal/globals';

config({ ssrFadeout: true });

const Index = ({ data, location, pageContext }) => {
  useEffect(() => {
    scroller.scrollTo(get(location, ["state", "scrollTo"], null), {
      duration: 500,
      delay: 500,
      smooth: true,
    })
  }, [])

  const orderedSections = data.strapiPage.sections
  const brand = data.backendData.brand
  const { crmOption } = pageContext
  return (
    <Block>
      <Header inIndexPage crmOption={crmOption} />
      <Block mt={['64px', '80px']} />
      {orderedSections.map(({ Name }) => (
        <Section sectionName={Name} key={Name} brand={brand} />
      ))}
      <ContactUs />
      <Footer />
    </Block>
  )
}

export default withTheme()(Index)

export const IndexQuery = graphql`
  query IndexQuery($name: String, $brandId: String) {
    strapiPage(Name: { eq: $name }) {
      sections {
        Name
      }
    }
    backendData {
      brand(brandId: $brandId) {
        id
        cover
        logo
        name
        gradientColor
        description
        textColor
        primaryColor
        rewards {
          id
          title
          description
          image
          point
        }
        branches {
          displayName
          id
          location
          lng
          lat
          openHour
          tel
          information
          shopImage
          galleries
        }
      }
    }
  }
`
